<template>
  <dash-page-new
      :title="$t('Orders')"
      :subtitle="$t('Orders')"
      icon="mdi-receipt-text-check-outline"
      :root="$t('Dashboard')"
      :root-location="businessDashLink()"
      :filters="filterItems"
      @filter="filter = $event"
  >

    <template #default>

      <div v-if="!SM"  class="d-flex justify-end align-center pr-4 mt-4">
        <v-btn @click="displayDeleted = !displayDeleted" :color="wsACCENT" text class="noCaps mr-2"  >
          <v-icon>{{ displayDeleted ? 'mdi-chevron-left' : 'mdi-delete' }}</v-icon>
          <h5>{{   $t( !displayDeleted ? 'TrashBin' : 'Return')  }} {{ !displayDeleted ? `(${canceledOrders})` : ordersTotal }} </h5>
        </v-btn>
<!--        <v-btn dark   :color="wsATTENTION" class="noCaps" >-->
<!--          <v-icon>mdi-plus</v-icon>-->
<!--          {{  $t('AddOrder')  }}-->
<!--        </v-btn>-->
      </div>

      <v-sheet dark class="wsRoundedHalf d-flex justify-center mt-5 py-3 px-6"
               :color="wsDARK" style="background: linear-gradient(25deg, rgba(47, 84, 152, 0.76), rgba(157, 60, 0, 0.57))">

        <v-row class="pa-0 ma-0">
          <v-col cols="6" md="3" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">{{  $t( !displayDeleted ? 'NewOrders' : 'PaymentNotConfirmed' ) }}</h6>
            <h3 class="text-center"> + {{ unpaidOrders  }}</h3>
          </v-col>
          <v-col cols="6" md="3" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">{{  $t('PaymentConfirmed')  }}</h6>
            <h3 class="text-center">{{ payedOrders }} / {{ totalOrders }}</h3>
          </v-col>
          <v-col cols="6" md="3" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">{{  $t('PayedPercent')  }}</h6>
            <h3 class="text-center">{{  payedPercent  }} %</h3>
          </v-col>
          <v-col cols="6" md="3" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">{{  $t('Income')  }}</h6>
            <h3 class="text-center">{{ totalIncome }}k {{ BUSINESS_CURRENCY }}</h3>
          </v-col>
        </v-row>


      </v-sheet>
    </template>
    <template #defaultDesktop>
      <!-- :: MAIN CONTENT :: -->
        <ws-data-table
            :items="ordersFiltered"
            :headers="ordersHeaders"
            class="mt-6 pt-0 wsHoverLight"
            selectable-key="id"
            :disable-sort="displayShow('sm')"
            :search="filter.search"
            hide-default-header
        >

          <template v-slot:item="{item}">

            <tr>

              <!-- Date-->
              <td  class=" text-no-wrap" width="10px">
                <h5 :style="`color : ${wsACCENT}`">{{ PARSE_TIME_STAMP(item.date,true) }} </h5>
                <h5>{{ item.order_id }} </h5>
              </td>
              <!-- OrderNumber-->
              <!-- Name-->
              <td width="10px" class="text-no-wrap">
                <h5 :style="`color : ${wsACCENT}`">{{ item.name }} </h5>
                <h5 class=" grey--text text--darken-3 ">{{ item.phone }}</h5>
              </td>
              <!-- Course-->
              <td>
                <h5 class="">{{ item.course }} </h5>
              </td>
              <!-- Subscription Period-->
              <td width="10px">
                <div class="d-flex" >
                  <h5 v-if="item.is_subscription"
                      class="text-no-wrap">
                    <span v-if="item.period_type !== 'custom' ">
                      {{ $tc( item.period_type , item.subscription_period ) }}
                    </span>
                    <span v-else>
                         {{ $tc( 'day' , item.subscription_period*item.period_days ) }}
                    </span>

                  </h5>
                </div>
              </td>
              <!-- Price-->
              <td width="10px">
                <div class="d-flex">
                  <h5 class="text-no-wrap">{{ item.price }} {{ BUSINESS_CURRENCY }}</h5>
                </div>
              </td>
              <!-- Discount-->
              <td width="10px">
                <div v-if="item.discount" class="d-flex">
                  <h5 class="text-no-wrap">{{ item.discount }} %</h5>
                </div>
              </td>
              <!-- Promocode-->
              <td width="10px">
                <div v-if="item.promocode" class="d-flex">
                  <h5 class="text-no-wrap">{{ item.promocode }}</h5>
                </div>
              </td>
              <!--Payement Status-->
              <td align="center" class="px-0" width="10px" >

                <ft-select
                    @input="openConfirmation('payed',item, $event)"
                    :value="item.payed"
                    :items="CHOICE_PAYED"
                    :disabled="displayDeleted"
                >
                  <template #default="{text}">

                    <v-chip
                        :color="item.payed ? wsSUCCESS : null"
                        :class="!displayDeleted ? 'pointer' : null"
                        dark
                    >
                      <h5 class="text-no-wrap " :class="displayDeleted ? 'px-2' : 'pl-2' " >
                        {{ text }}
                        <v-icon v-if="!displayDeleted" >mdi-chevron-down</v-icon>
                      </h5>

                    </v-chip>

                  </template>

                </ft-select>

              </td>
              <!-- Action-->
              <td width="10px">
                <v-btn
                    @click="openConfirmation('status',item, (!displayDeleted ? 2 : (item.payed ? 1 : 0)  ) , true) "
                    :color="wsACCENT" icon>
                  <v-icon>mdi-{{ !displayDeleted ? 'delete' : 'history' }}</v-icon>
                </v-btn>
              </td>

            </tr>
          </template>

        </ws-data-table>
    </template>
    <template #defaultMobile>

      <div class="d-flex justify-end align-center pr-4 mt-4">
        <v-btn @click="displayDeleted = !displayDeleted" :color="wsACCENT" text class="noCaps mr-2"  >
          <v-icon>{{ displayDeleted ? 'mdi-chevron-left' : 'mdi-delete' }}</v-icon>
          <h5>{{   $t( !displayDeleted ? 'TrashBin' : 'Return')  }} {{ !displayDeleted ? `(${canceledOrders})` : ordersTotal }} </h5>
        </v-btn>
      </div>


      <!-- mobile-->
      <v-data-iterator
          disable-sort
          items-per-page="50"
          :items="ordersFiltered"
          :headers="ordersHeaders"
          :search="filter.search"
      >
        <template v-slot:default="{ items  }">

          <v-sheet  v-for="(item,i) in items" :key="i" >

            <v-sheet
                :style="`border : 1px solid ${wsBACKGROUND} !important`"
                class="mt-5 wsRoundedLight pa-3"
            >

              <div class="d-flex justify-space-between mb-2">
                <h5 class="text-no-wrap">
                  <v-icon :color="wsACCENT">mdi-calendar</v-icon>
                  {{ PARSE_TIME_STAMP(item.date) }}
                </h5>
                <h5 class="text-no-wrap"> {{ item.price }} {{ BUSINESS_CURRENCY }}</h5>
              </div>

              <v-divider :style="`border-color : ${wsBACKGROUND}`" />


              <ft-select
                  @input="openConfirmation('payed',item, $event)"
                  :value="item.payed"
                  :items="CHOICE_PAYED"
                  :disabled="displayDeleted"
              >
                <template #default="{text}">

                  <v-sheet
                      :color="item.payed ? wsSUCCESS : 'grey'"
                      :class="!displayDeleted ? 'pointer py-1' : null"
                      height="35"
                      class="d-flex  px-2 wsRoundedLight align-center justify-space-between mt-3"
                      style="width: 100%"
                      dark
                  >
                    <span ></span>
                    <h5 class="text-no-wrap text-center " :class="displayDeleted ? 'px-2' : 'pl-2' " >
                      {{ text }}
                    </h5>
                    <span>
                       <v-icon v-if="!displayDeleted" >mdi-chevron-down</v-icon>
                    </span>

                  </v-sheet>


                </template>

              </ft-select>


              <div>
                <h5 class="mt-5">
                  <span class="font-weight-medium" :style="`color : ${wsACCENT}`">
                    {{ $t('Client') }}
                  </span> : {{ item.name }}
                </h5>
                <h5 class="mt-5">
                  <span class="font-weight-medium" :style="`color : ${wsACCENT}`">
                    {{ $t('Course_') }}
                  </span> : {{ item.course }}
                </h5>
                <h5 class="mt-5">
                  <span class="font-weight-medium" :style="`color : ${wsACCENT}`">
                    {{ $t('Phone') }}
                  </span> : {{ item.phone }}
                </h5>
                <h5 class="mt-5">
                  <span class="font-weight-medium" :style="`color : ${wsACCENT}`">
                    {{ $t('Email') }}
                  </span> : {{ item.email }}
                </h5>
              </div>

              <div class="d-flex justify-end align-center mt-4">
                <v-btn
                    @click="openConfirmation('status',item, (!displayDeleted ? 2 : (item.payed ? 1 : 0)  ) , displayDeleted) "
                    :color="wsACCENT" icon>
                  <v-icon>mdi-{{ !displayDeleted ? 'delete' : 'history' }}</v-icon>
                </v-btn>
              </div>



            </v-sheet>


          </v-sheet>
        </template>
      </v-data-iterator>
    </template>

    <template #dialog>
      <orderDetails
          v-if="displayOrderDetails"
          v-model="displayOrderDetails"
          :order="orderDetailsInput"
          @changeOrder="changeOrder"
      />
      <wsDialog v-model="displayConfirm"
                :loading="$store.state.LOADING"
                @save="confirmation()"
                :title="selectedOrder.selectedKey === 'status' ? $t('DeleteOrder') : $t('EditOrder')"
                :saveText="$t('Confirm')"
                :display-confirm-delete="selectedOrder.selectedKey === 'status'"
                :confirm-delete-text="$t('courses.order.edit.confirm.cancel')"
                close-delete
                @delete="confirmation"
      >
        <h4 class="text-center py-6"> {{  selectedOrder.selectedKey === 'payed' ? $t('courses.order.edit.confirm.payed') :  $t('courses.order.edit.confirm.cancel')  }}</h4>
      </wsDialog>
    </template>

  </dash-page-new>
</template>

<script>

import orderDetails from "@/components/pages/businessDashboard/businessOrders/orderDetails";
import {mapActions, mapState} from "vuex";
import ftSelect from "@/components/UI/ftSelect";
export default {
  name: "BusinessDashboardOrders",
  components : {
    orderDetails,
    ftSelect
  },
  data (){
    return {
      referralsSelect : [],
      displayDeleted : false,
      filter : {},
      
      selectedOrder : {},
      loading : false,


      displayConfirm : false,

      displayOrderDetails : false,
      orderDetailsInput : {},

      orderView : 0,
      orders : [],


    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    ...mapState('notifications',['notifications']),

    filterItems() {
      let items = [

        { text : this.$t('Payment') , value : 'payed'    , type : 'select' , items : this.CHOICE_PAYED_SELECT },
        { text : this.$t('Course_')  , value : 'course'   , type : 'select' , items : this.distinctCourses },
        { text : this.$t('Client')  , value : 'name'     , type : 'select' , items : this.distinctClients },
      ]

      if ( this.referralsSelect.length > 0 ) {
        items.push({ text : this.$t('Referrals') , value : 'referral' , type : 'select' , items : this.referralsSelect })
      }

      return items
    },

    // ORDERS
    ordersFiltered() {
      var items = this.orders
      if ( items.length === 0 ) {
        return []
      }

      if ( this.displayDeleted ) {
        items = items.filter(el=> el.status === 2)
      } else {
        items = items.filter(el=> el.status !== 2)
      }

      if ( this.filter.payed || this.filter.payed === false ) {
        items = items.filter(el=> el.payed === this.filter.payed)
      }
      if ( this.filter.course ) {
        items = items.filter(el=> el.course === this.filter.course)
      }
      if ( this.filter.name  ) {
        items = items.filter(el=> el.name === this.filter.name)
      }

      if ( this.filter.referral ) {
        items = items.filter( el=> el.referral ===  this.filter.referral )
      }

      return items
    },
    ordersHeaders() {
      var headers = [
        {text: this.$t('Date') , value:'date', sortable : false},
        {text: this.$t('Client')   , value:'name', sortable : false, align: 'left'},
        {text: this.$t('Course_')    , value:'course', align : 'left'},
        {text: this.$t('Subscription') , value:'subscription_period', align : 'center'},
        {text: this.$t('Price')    , value:'price', align : 'center'},
        {text: this.$t('Discount')    , value:'discount', align : 'center'},
        {text: this.$t('PromoCode')    , value:'promocode', align : 'center'},
        {text: this.$t('Payment')  , value:'payment', align : 'center'},
        {text:'',value:'action'},
        {value:'order_id', sortable : false , align: ' d-none'},
        {value:'email', sortable : false, align: ' d-none'},
        {value:'phone', sortable : false, align: ' d-none'},
      ]

      return headers
    } ,
    distinctCourses() {
      let items = []
      if (this.orders.length === 0) {
        return []
      }
      let courses = this.orders.map(item => item.course)
            .filter((value, index, self) => self.indexOf(value) === index)

      courses.forEach((course)=>{
          items.push({ text : course, value : course })
        })


      return items
    },
    distinctClients() {
      let items = []
      if (this.orders.length === 0) {
        return []
      }
      let clients = this.orders.map(item => item.name)
          .filter((value, index, self) => self.indexOf(value) === index)

      clients.forEach((client)=>{
        items.push({ text : client, value : client })
      })


      return items
    },

    totalIncome() {
      let orders = this.ordersFiltered
      if ( orders.length === 0 ) {
        return 0
      }

      let price = 0
      orders.forEach(el => {
        price += el.payed ? el.price : 0
      })

      return (price/1000).toFixed(1) || 0
    },
    canceledOrders() {
      let orders = this.orders
      orders = orders.filter(el=> el.status === 2 )
      return orders.length || 0
    },
    payedOrders() {
      let orders = this.ordersFiltered
      orders = orders.filter(el=> el.payed )
      return orders.length || 0
    },
    unpaidOrders() {
      let orders = this.ordersFiltered
      orders = orders.filter(el=> !el.payed )
      return orders.length || 0
    },
    totalOrders() {
      let orders = this.ordersFiltered
      return orders.length || 0
    },
    payedPercent() {
      return Math.round((this.payedOrders/this.totalOrders)*100) || 0
    },

  },
  watch : {
    'EVENTS_TRIGGER.ORDER_COURSE'() {
      this.initPage()
    },
    'EVENTS_TRIGGER.ORDER_COURSE_PAYMENT'() {
      this.initPage()
    },

  },
  methods : {
    ...mapActions('course_orders',[
      'GET_BUSINESS_COURSES_ORDERS',
      'EDIT_BUSINESS_COURSE_ORDER'
    ]),

    changeOrder(input){
      this.displayOrderDetails = input
    },
    async editOrder() {

      let result = await this.EDIT_BUSINESS_COURSE_ORDER(this.selectedOrder)
      if ( !result ) {
        return
      }

      let index = this.orders.findIndex(el=>el.id === this.selectedOrder.uuid)
      if ( index === -1 ) {
        return
      }
      this.orders[index].payed = result.payed
      this.orders[index].status = result.status

      this.orders = JSON.parse(JSON.stringify(this.orders))

      this.notify(this.$t('ChangesSaved'))


    },
    openDetails(order) {
      this.displayOrderDetails = true
      this.orderDetailsInput = order
    },
    confirmation() {
      this.displayConfirm = false
      this.editOrder();
    },
    openConfirmation(type,order,value=null , noConfirm = false){
      if ( !noConfirm ) {
        this.displayConfirm = true
      }

      this.selectedOrder = {
        uuid : order.id,
        [type] : value,
        selectedKey : type
      }
      if ( noConfirm ) {
        this.confirmation()
      }
    },

    // technical
    async initPage() {
      this.loading = true

      let result = await this.GET_BUSINESS_COURSES_ORDERS()
      if ( result && result !== true ) {
        this.orders = result.orders
        this.referralsSelect = result.referrals
        this.orders.reverse()
      }



      await this.READ_EVENTS('order_course')
      await this.READ_EVENTS('order_course_payment')
    }

  },
  mounted(){
   this.initPage()
  }

}
</script>

<style scoped>
.anim {
  cursor : pointer;
  transition: all 0.3s ease;
}
.smallText {
  font-size : 0.875rem
}
</style>